import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';

const Features = () => {
    useEffect(() => {
        document.title = "NewsInside | Features"
    }, []);
    return (
        <section className="py-6 mt-5">
            <div className="bg-holder" style={{ backgroundImage: `url('assets/img/illustrations/hero-bg.png')`, backgroundPosition: 'bottom', backgroundSize: 'cover' }}></div>

            <div className="container position-relative">
                <div className="row py-5">
                    <div className="col-md-5 col-lg-6 order-md-0 text-center text-md-start"><img className="img-fluid image-fade" src="assets/img/illustrations/feature-bg.png" width="450" alt="" /></div>
                    <div className="col-md-7 col-lg-6 px-sm-5 px-md-0">
                        <h6 className="fw-bold fs-4 display-3 lh-sm">NewsInside features</h6>
                        <p className="my-4">The NewsInside app offers users a comprehensive collection of the most current news stories and updates across a variety of categories. These include Breaking News and Top Stories, Politics, Business, Technology, Sports, Entertainment, Lifestyle, and regional news feeds. All information is sourced from reputable and trustworthy news outlets.</p>
                        <div className="d-flex align-items-center mb-5">
                            <div><img className="img-fluid" src="assets/img/illustrations/my_ni.png" width="60" alt="" /></div>
                            <div className="px-4">
                                <h5 className="fw-bold text-danger">Reading Experience</h5>
                                <p>An Outstanding and Immersive News Reading Experience Providing you with concise and summarized news stories</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-5">
                            <div><img className="img-fluid" src="assets/img/illustrations/about.png" width="60" alt="" /></div>
                            <div className="px-4">
                                <h5 className="fw-bold text-warning">Multiple Languages</h5>
                                <p>Read English and various Indian regional languages. A compilation of language-related news updates gathered from multiple online sources.</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-5">
                            <div><img className="img-fluid" src="assets/img/illustrations/readinglist.png" width="60" alt="" /></div>
                            <div className="px-4">
                                <h5 className="fw-bold text-success">Reading List</h5>
                                <p>A Compilation of Articles to Explore that are Comparable to the Bookmarks that we have consistently utilized over the years However, with a more clearly defined and deliberate objective</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features;