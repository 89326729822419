import { Link, useLocation } from 'react-router-dom';

const Header = () => {

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    return (
        <nav className="navbar navbar-expand-lg navbar-light fixed-top py-3" data-navbar-on-scroll="data-navbar-on-scroll">
            <div className="container">
                <Link className="navbar-brand d-flex align-items-center fw-bold fs-2" to="/">
                    <img className="img-fluid" src="assets/img/logo.png" width="50" alt="" />
                </Link>
                <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse border-top border-lg-0 mt-4 mt-lg-0" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto pt-2 pt-lg-0">
                        <li data-anchor="data-anchor" className={splitLocation[1] === "" ? "nav-item active" : "nav-item"}><Link className="nav-link fw-medium" to="/">Home</Link></li>
                        <li data-anchor="data-anchor" className={splitLocation[1] === "advertise" ? "nav-item active" : "nav-item"}><Link className="nav-link fw-medium" to="/advertise">Advertise</Link></li>
                        <li data-anchor="data-anchor" className={splitLocation[1] === "features" ? "nav-item active" : "nav-item"}><Link className="nav-link fw-medium" to="/features">Features</Link></li>
                        <li data-anchor="data-anchor" className={splitLocation[1] === "contact" ? "nav-item active" : "nav-item"}><Link className="nav-link fw-medium" to="/contact">Contact</Link></li>
                    </ul>
                </div>
            </div>
        </nav >
    )
}

export default Header;