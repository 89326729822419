import React, { useEffect } from 'react';

const Contact = () => {
    useEffect(() => {
        document.title = "NewsInside | Contact Us"
    }, []);
    return (
        <section className="py-6 mt-5">
            <div className="bg-holder" style={{ backgroundImage: `url('assets/img/illustrations/hero-bg.png')`, backgroundPosition: 'bottom', backgroundSize: 'cover' }}></div>

            <div className="container position-relative">
                <div className="row py-5">
                    <div className="col-md-5 col-lg-6 order-md-0 text-center text-md-start"><img className="img-fluid image-fade" src="assets/img/illustrations/contact-bg.png" width="450" alt="" /></div>
                    <div className="col-md-7 col-lg-6 px-sm-5 px-md-0">
                        {/* <div className="col-md-12 text-center text-md-start"> */}
                        <h6 className="fw-bold fs-4 display-3 lh-sm">Contact Us</h6>
                        <p className="my-4">Get started with newsinside.in - Head over to our 'What is NewsInside' page to learn more about our
                            current offering. Please feel free to fill out the contact form and we'd be happy to get in touch with
                            you.</p>
                        <p>General & Press - For all general and press inquiries, email contact@newsinside.in</p>
                        <p>Report Corrections/Abuse/Porn/Copyright/Privacy/Legal Violations - NewsInside is an auto-curated content
                            and social media aggregation site. We use publicly available social media posts on the web to index,
                            analyze
                            and organize the most popular content in the context of related stories, users to find relevant related
                            information easily.</p>
                        <p>Put our best efforts to respect user privacy and filter out adult/abusive/violent content. Yet, if you
                            find any content on newsinside.in objectionable, please report it by emailing us at contact@newsinside.in
                            including a link to that particular content page. We will review and duly process your request and take
                            the necessary action at the earliest.</p>
                        <p>Feedback - For any kind of feedback, email feedback@newsinside.in</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Contact;
