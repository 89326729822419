import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Notfound = () => {
    useEffect(() => {
        document.title = "NewsInside | 404"
    }, []);
    return (
        <section className="py-6 mt-5">
            <div className="bg-holder" style={{ backgroundImage: `url('assets/img/illustrations/hero-bg.png')`, backgroundPosition: 'bottom', backgroundSize: 'cover' }}></div>
            <div className="container position-relative">
                <div className="row align-items-center py-6">
                    <div className="col-md-5 order-md-0 text-center text-md-start"><img className="img-fluid image-fade mb-4" src="/assets/img/illustrations/no_connection.png" width="400" alt="" /></div>
                    <div className="col-md-6 text-center text-md-start offset-md-1">
                        <h6 className="fw-bold fs-4 display-3 lh-sm">Page Not Found</h6>
                        <p className="my-4 pe-xl-5">The page you were looking for could not be found. It might have been removed, renamed, or did not exist in the first place.</p>
                        <Link className="btn btn-lg btn-danger rounded-pill hover-top" to="/" role="button">Home</Link>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Notfound;