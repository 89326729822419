import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import './App.css';
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import Advertise from "./Advertise";
import Contact from "./Contact";
import Features from "./Features";
import Privacy from "./Privacy";
import Notfound from "./Notfound";
import ScrollToTop from "./ScrollToTop";

function App() {
    return (
        <Router>
            <Header />
            <main>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    {/* <Route path="/about" component={About} /> */}
                    <Route path="/advertise" element={<Advertise />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/features" element={<Features />} />
                    <Route path="/privacy_policy" element={<Privacy />} />
                    <Route path="*" element={<Notfound />} />
                </Routes>
            </main>
            <Footer />
            <ScrollToTop />
        </Router>
    );
}

export default App;
