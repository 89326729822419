import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    useEffect(() => {
        document.title = "NewsInside | Get latest news in your language from trusted sources"
    }, []);
    return (
        <section className="py-0" id="home">
            <div className="bg-holder" style={{ backgroundImage: `url('assets/img/illustrations/hero-bg.png')`, backgroundPosition: 'bottom', backgroundSize: 'cover' }}></div>

            <div className="container position-relative">
                <div className="row align-items-center py-8">
                    <div className="col-md-5 col-lg-6 order-md-1 text-center text-md-end"><img className="img-fluid image-fade"
                        src="assets/img/illustrations/mobile.png" width="350" alt="" /></div>
                    <div className="col-md-7 col-lg-6 text-center text-md-start">

                        <h1 className="mb-4 display-3 fw-bold lh-sm">Explore News Stories from Every Perspective with <span className="fw-bold ni-primary"><b> NewsInside</b></span></h1>
                        <p className="mt-3 mb-4 fs-1">Discover the ultimate application tailored for your contemporary lifestyle, offering concise and summarized news stories specifically curated for you. Enjoy an outstanding news reading experience with this app.</p>

                        <div className="d-flex justify-content-center d-md-inline-block">
                            <Link to="/" className="pe-2 pe-sm-3 pe-md-4"><img src="assets/img/illustrations/google-play.png" width="160"
                                alt="NewsInside App Google Play Store" /></Link>
                            <Link to="/"><img src="assets/img/illustrations/app-store.png" width="160" alt="NewsInside App Store" /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section >

    )
}

export default Home;