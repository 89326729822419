import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Privacy = () => {
    useEffect(() => {
        document.title = "NewsInside | Privacy Policy"
    }, []);
    return (
        <section className="py-6 mt-5">

            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-12 text-center text-md-start">
                        <h6 className="fw-bold fs-4 display-3 lh-sm">Privacy Policy</h6>
                        <p className="my-4">This page is used to inform visitors regarding NewsInside policies with the
                            collection, use, and disclosure of Personal Information if anyone decided to use NewsInside Service.</p>
                        <p>If you choose to use our Service, then you agree to the collection and use of information in relation to
                            this policy. The Personal Information that We collect is used for providing and improving the Service. We
                            will not use or share your information with anyone except as described in this Privacy Policy.</p>
                        <p>
                            The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is
                            accessible at NewsInside unless otherwise defined in this Privacy Policy.
                        </p>
                        <h6 className="fw-bold fs-4 display-3 lh-sm">Information Collection and Use</h6>
                        <p>For a better experience, while using our Service, We may require you to provide us with certain
                            personally identifiable information, including but not limited to Device type and Device info. The
                            information that we request will be retained on your device and is not collected by us in any way.</p>
                        <p>The app does use third party services that may collect information used to identify you.</p>
                        <p>Link to privacy policy of third party service providers used by the app</p>

                        <p><a href="https://policies.google.com/privacy" rel="noreferrer" target="_blank">Google Play Services</a></p>

                        <h6 className="fw-bold fs-4 display-3 lh-sm">Your Agreement with NewsInside</h6>

                        <p>Your access and use of the Site and the Services provided, downloading and/or installing the mobile
                            application on your device, or accessing or using the Site or the mobile application or any of the content
                            available within therein from any device hereunder constitute an acceptance of the terms and conditions
                            herein..</p>

                        <h6 className="fw-bold fs-4 display-3 lh-sm">System and Services Description</h6>
                        <p>NewsInside is a news aggregation system which also offers users the facility to upload their content.</p>

                        <ol style={{ listStyleType: 'lower-alpha' }}>
                            <li>NewsInside runs a crawler software that discovers and analyzes millions of news articles, blog posts
                                shared on various social network platforms and the wider internet to provide personalized news discovery
                                experience to its users. Some of the key features of NewsInside are:
                                <ol>
                                    <li>NewsInside generates quick story summaries optimized for mobile reading.</li>
                                    <li>Clicking on the source displayed with stories provides source based feeds.</li>
                                    <li>NewsInside language editions provide story feeds based on user's language preferences.</li>
                                </ol></li>
                            <li>NewsInside also allows users to collate news they like and want to share with their friends and other
                                on the NewsInside News App.</li>
                        </ol>
                        <h6 className="fw-bold fs-4 display-3 lh-sm">Your Content On NewsInside</h6>
                        <p>Publishers can opt-out from NewsInside platform in two ways. (a) By filling our Opt-in/Opt-out form here
                            as part of our publisher program or Alternatively they can send an e-mail to contact [at] newsinside [dot]
                            in. (b) By following robots exclusion protocol.</p>

                        <h6 className="fw-bold fs-4 display-3 lh-sm">Third Party Websites</h6>
                        <p>Third Party Websites: The Service may provide, or third parties may provide, links or other access to
                            other sites and resources on the Internet. NewsInside has no control over such sites, resources and
                            NewsInside is not responsible for and does not endorse such sites and resources. You further acknowledge
                            and agree that NewsInside will not be responsible or liable, directly or indirectly, for any damage or
                            loss caused or alleged to be caused by or in connection with use of or reliance on content, events, goods
                            or services available on or through any such site or resource. Any dealings you have with third parties
                            found while using the Service are between you and the third party, and you agree that NewsInside is not
                            liable for any loss or claim that you may have against any such third party.</p>

                        <h6 className="fw-bold fs-4 display-3 lh-sm">Log Data</h6>
                        <p>We want to inform you that whenever you use our Service, in a case of an error in the app We collect data
                            and information (through third party products) on your phone called Log Data. This Log Data may include
                            information such as your device Internet Protocol ("IP") address, device name, operating system version,
                            the configuration of the app when utilizing NewsInside Service, the time and date of your use of the
                            Service, and other statistics.</p>

                        <h6 className="fw-bold fs-4 display-3 lh-sm">Cookies</h6>
                        <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers.
                            These are sent to
                            your browser from the websites that you visit and are stored on your device's internal memory.
                        </p>
                        <p>This Service does not use these "cookies" explicitly. However, the app may use third party code and
                            libraries that use "cookies" to collect information and improve their services. You have the option to
                            either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose
                            to refuse our cookies, you may not be able to use some portions of this Service.</p>

                        <h6 className="fw-bold fs-4 display-3 lh-sm">Service Providers</h6>
                        <p>We may employ third-party companies and individuals due to the following reasons:</p>
                        <p><b>To provide the Service on our behalf;</b></p>
                        <p><b>To perform Service-related services; or</b></p>
                        <p><b>To assist us in analyzing how our Service is used.</b></p>
                        <p><b>To facilitate our Service;</b></p>
                        <p>We want to inform users of this Service that these third parties have access to your Personal
                            Information. The reason is
                            to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use
                            the information
                            for any other purpose.
                        </p>

                        <h6 className="fw-bold fs-4 display-3 lh-sm">Security</h6>
                        <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially
                            acceptable means of protecting it. But remember that no method of transmission over the internet, or
                            method of electronic storage is 100% secure and reliable, and We cannot guarantee its absolute security.
                        </p>

                        <h6 className="fw-bold fs-4 display-3 lh-sm">Links to Other Sites</h6>
                        <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed
                            to that site. Note that these external sites are not operated by me. Therefore, We strongly advise you to
                            review the Privacy Policy of these websites. We have no control over and assume no responsibility for the
                            content, privacy policies, or practices of any third-party sites or services.</p>

                        <h6 className="fw-bold fs-4 display-3 lh-sm">Children's Privacy</h6>
                        <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally
                            identifiable information from children under 13 years of age. In the case We discover that a child under
                            13 has provided us with personal information, We immediately delete this from our servers. If you are a
                            parent or guardian and you are aware that your child has provided us with personal information, please
                            contact us so that We will be able to do necessary actions.</p>

                        <h6 className="fw-bold fs-4 display-3 lh-sm">Changes to This Privacy Policy</h6>
                        <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page
                            periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this
                            page.</p>
                        <p>This policy is effective as of 2022-10-28</p>

                        <h6 className="fw-bold fs-4 display-3 lh-sm">Contact Us</h6>
                        <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to <Link
                            to="/contact">Contact Us.</Link></p>
                    </div>
                </div>
            </div>
        </section >

    )
}
export default Privacy;