import { Link } from 'react-router-dom';

const Footer = () => {
    return (

        <section className="py-8 bg-1000">
            <div className="container">
                <div className="row flex-center">
                    <div className="col-auto mb-5">
                        <Link className="pe-2 d-flex align-items-center text-decoration-none fw-bold fs-3" to="/">
                            <img className="img-fluid" src="assets/img/logo.png" width="50" alt="" />
                        </Link>
                    </div>
                </div>
                <div className="row flex-center">
                    <div className="col-auto mb-5">
                        <ul className="list-unstyled list-inline mb-0">
                            <li data-anchor="data-anchor" className="list-inline-item me3 me-sm-4"><Link className="text-light text-decoration-none" to="/">Home</Link></li>
                            <li data-anchor="data-anchor" className="list-inline-item me3 me-sm-4"><Link className="text-light text-decoration-none" to="/advertise">Advertise</Link></li>
                            <li data-anchor="data-anchor" className="list-inline-item me3 me-sm-4"><Link className="text-light text-decoration-none" to="/features">Features</Link></li>
                            <li data-anchor="data-anchor" className="list-inline-item me3 me-sm-4"><Link className="text-light text-decoration-none" to="/contact">Contact</Link></li>
                            <li data-anchor="data-anchor" className="list-inline-item me3 me-sm-4"><Link className="text-light text-decoration-none" to="/privacy_policy">Privacy</Link></li>
                            <li data-anchor="data-anchor" className="list-inline-item me3 me-sm-4"><Link className="text-light text-decoration-none" to="/faq">FAQ</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="row flex-center">
                    <div className="col-auto">
                        <p className="mb-0 fs--1 text-700">&copy; 2020 NewsInside. All rights reserved</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer;