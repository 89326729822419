import React, { useEffect } from 'react';
// import { Link } from "react-router-dom";

const Advertise = () => {
    useEffect(() => {
        document.title = "NewsInside | Advertise with us"
    }, []);
    return (
        <section className="py-6 mt-5">
            <div className="bg-holder" style={{ backgroundImage: `url('assets/img/illustrations/hero-bg.png')`, backgroundPosition: 'bottom', backgroundSize: 'cover' }}></div>

            <div className="container position-relative">
                <div className="row py-5">
                    <div className="col-md-5 col-lg-6 order-md-0 text-center text-md-start"><img className="img-fluid image-fade" src="assets/img/illustrations/adve-bg.png" width="450" alt="" /></div>
                    <div className="col-md-7 col-lg-6 px-sm-5 px-md-0">
                        {/* <div className="col-md-12 text-center text-md-start"> */}
                        <h6 className="fw-bold fs-4 display-3 lh-sm">Advertise with us</h6>
                        <p className="my-4">Thank you for reaching out to NewsInside. We are here to help you showcase native and branded content across our digital properties. Our platform reaches mobile, apps, and web across the length and breadth of India. Let's work together to and maximise the reach of your brand.</p>
                        <p>For advertisers, NewsInside has no rival. Below are resources for you to connect with our Advertising Sales team in order to learn more about and how your brand can connect with our audience.</p>
                        <p>Please write to advertise@newsinside.in</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Advertise;
