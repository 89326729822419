// import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    setTimeout(() => (routePath) ? onTop() : onTop(), 10);
    return null;
}
